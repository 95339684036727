import { ProductDetail } from "@/utils/APIRouteTypes"
import { cn } from "@/utils/cn"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { ArrowLeftCircle, ChevronLeft, ChevronRight, Download } from "lucide-react"
import Image from "next/image"
import React, { useEffect, useState } from "react"
import ImageWithLoader from "../others/ImageWithLoader"
import { useGeneratedImages } from "@/contexts/GeneratedImagesProvider"
import { AnimatePresence, motion, LayoutGroup } from "framer-motion";

type ImageViewModalProps = {
    downloadEnabled?: boolean
    currentImage: string
    setCurrentImage: (n: string) => void
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    children?: React.ReactNode
}

const buttonClass = "p-1 sm:p-2 bg-black/40 rounded-full text-white disabled:bg-transparent"

export default function GeneratedImageViewModal(props: ImageViewModalProps) {

    const [generatedImages, setGeneratedImages] = useGeneratedImages()

    const currentImgIndex = generatedImages.findIndex((item) => item.src === props.currentImage)

    const nextImage = currentImgIndex >= generatedImages.length - 1 ? null : generatedImages[currentImgIndex + 1]
    const previousImage = currentImgIndex <= 0 ? null : generatedImages[currentImgIndex - 1]

    const handleNextClick = () => {
        if (nextImage) {
            if (!nextImage.isClicked) {
                setGeneratedImages((prev) => {
                    return prev.map((item, index) => {
                        if (index === currentImgIndex + 1) {
                            return { ...item, isClicked: true }
                        }
                        return item
                    })
                })
            }
            props.setCurrentImage(nextImage.src)
        }
    }

    const handlePreviousClick = () => {
        if (previousImage) {
            if (!previousImage.isClicked) {
                setGeneratedImages((prev) => {
                    return prev.map((item, index) => {
                        if (index === currentImgIndex - 1) {
                            return { ...item, isClicked: true }
                        }
                        return item
                    })
                })
            }
            props.setCurrentImage(previousImage.src)
        }
    }

    return (
        <Dialog open={props.open} onOpenChange={props.setOpen}>
            <DialogContent className="z-[999]">
                <DialogHeader className="m-0 py-2 ">
                    <DialogTitle className="font-semibold text-gray-900">
                        Image Preview
                    </DialogTitle>
                </DialogHeader>
                <div className='relative flex flex-col justify-center transition-all duration-300 overflow-hidden'>
                    <div className='absolute flex justify-between items-center top-0 left-0 size-full rounded-lg z-20'>

                        <button onClick={handlePreviousClick} disabled={previousImage == null} className={buttonClass} id="previous-generated-image-button">
                            <ChevronLeft className="size-5 sm:size-7" />
                        </button>

                        <button onClick={handleNextClick} disabled={nextImage == null} className={buttonClass} id="next-generated-image-button">
                            <ChevronRight className="size-5 sm:size-7" />
                        </button>

                    </div>
                    <motion.div
                        key={props.currentImage}
                        initial={{ opacity: 0, scale: 1.1, filter: "blur(8px)" }}
                        animate={{ opacity: 1, scale: 1, filter: "blur(0px)" }}
                        transition={{
                            delay: 0.1,
                            duration: 0.2,
                        }}
                    >
                        <ImageWithLoader
                            src={props.currentImage}
                            spinnerSize="xl"
                            fullImageRequired
                            alt='Generated Image'
                            width={500}
                            height={0}
                            className='rounded-lg bg-white'
                        />
                    </motion.div>
                    <span className=" mt-1 text-center text-xs text-gray-500">
                        *Final Image will be professionally edited
                    </span>
                </div>

                <DialogFooter className="px-1 py-2 pt-3 sm:px-2">
                    {props.children}
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
