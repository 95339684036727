'use client'
import { PlaceholdersAndVanishInput } from "@/components/ui/placeholders-and-vanish-input";
import { useNonUrlContext } from "@/contexts/NonUrlContext";
import { checkGeneratedImages, generateImages } from "@/server/server-actions";
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from "@/components/ui/tooltip";
import React, {
  useEffect,
  useState,
} from "react";
import ImageList from "./ImageList";
import { XCircleIcon } from "lucide-react";
import { useSession } from "next-auth/react";
import { useCommonContext } from "@/contexts/CommonContext";
import LetsTalk from "../detailComponents/letsTalk";
import * as amplitude from '@amplitude/analytics-browser'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { useGeneratedImages } from "@/contexts/GeneratedImagesProvider";
import FeedbackModal from "../others/feedbackModal";
import { useToast } from "@/hooks/use-toast";
import { ToastAction } from "@/components/ui/toast"
import { useSessionStorageContext } from "@/contexts/SessionStorageContextProvider";
import { AnimatePresence, motion, LayoutGroup } from "framer-motion";
import Link from "next/link";
import { Properties } from "@/utils/properties";
import { ColorType } from "@/utils/APIRouteTypes";

type ImageGenerateItemProps = {
  onReset?: () => void
  selectedImages: string[];
  handlePromptImageClick: (src: string) => void;
  productSlug?: string
  colorType?: ColorType
};


const placeholders = [
  "skull, lava",
  "a royal lion",
  "wolf, howling, full moon",
  "ironman, fire in the background",
  "polar bear, drinking a cocktail, in a desert",
  "astronaut in an alien planet",
  "hulk, smashing a car",
  "cute hippo with his son",
  "elephant, chasing away a car, snowy mountains",
  "spider man, swinging through buildings",
  "a valley at foothills of himalayas",
  "bear holding a gun",
  "snowy mountain",
  "rabbit, riding a rocket, in space",
  "a dolphin, flying through the clouds, smiling",
  "cat, scuba diving in deep sea",
  "husky, drinking beer",
  "majestic horse, running at a beach",
  "bird view of new york skyline",
  "fighter jet crashing in a forest",
  "bulldog, wearing sunglasses, smiling",
  "squirrel, surfing in sea",
  "cute pig rolling in the mud",
];

const checkBadPrompt = (prompt: string) => {
  const lowerPrompt = prompt.toLowerCase()
  const bannedWords = [
    't-shirt',
    'tshirt',
    't shirt',
    'phone case',
    'phone cover',
    'tank top',
    'tote bag',
    'poster',
    'totebag'
  ]
  // Check if prompt contains any banned words
  for (let word of bannedWords) {
    if (lowerPrompt.includes(word)) {
      return word
    }
  }
  return false
}

export const ImageGenerateItem = (props: ImageGenerateItemProps) => {
  const [inputValue, setInputValue] = React.useState("");
  const session = useSession()
  const { toast } = useToast()
  const [commonContext, setCommonContext] = useCommonContext()
  const [sessionStorage, setSessionStorage] = useSessionStorageContext()
  const [generatedImages, setGeneratedImages] = useGeneratedImages()

  const [showResetModal, setShowResetModal] = useState(false);
  const { sampleImages } = useNonUrlContext()

  const updateImages = async (rid: number, interval: NodeJS.Timeout) => {
    const req = await checkGeneratedImages(rid);
    if (
      req.images &&
      JSON.stringify(req.images) !== JSON.stringify(generatedImages)
    ) {
      setGeneratedImages([...req.images.map((item) => ({ src: item.src, isClicked: false, color: props.colorType || ColorType.LIGHT })), ...generatedImages]);
    }

    if (req.isDone) {
      setInputValue("");
      setSessionStorage(prev => (
        { ...prev, isGeneratingImage: false }
      ))
      clearInterval(interval);
    }
  };

  const onToastActionClick = () => {
    setCommonContext({ loginModalOpen: true, loginModalAction: 'imageGen' })
  }



  const handlePromptSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const promptInvalid = checkBadPrompt(inputValue)
    if (promptInvalid) {
      toast({
        title: "Bad Prompt",
        description: `Word "${promptInvalid}" is not allowed in the prompt.`,
        variant: 'destructive',
        duration: 6000,
        action: <ToastAction altText="See Examples" asChild><Link href={Properties.routes.EXPLORE}> See Examples</Link></ToastAction>
      })
      return
    }

    amplitude.logEvent('Prompt Sent!', { prompt: inputValue })
    if (session.status !== 'authenticated' && sessionStorage.numImagesGenerated > 0) {
      window.dataLayer?.push({
        event: 'generateImage',
        prompt: inputValue,
        askingForLogin: true,
        userID: null
      })

      toast({
        title: "Login Required",
        description: "Please login to continue. It is completely free!",
        variant: 'destructive',
        duration: 10000,
        action: <ToastAction altText="Login" onClick={onToastActionClick} onTouchStart={onToastActionClick}>Login</ToastAction>
      })
      return
    }

    window.dataLayer?.push({
      event: 'generateImage',
      prompt: inputValue,
      askingForLogin: false,
      userID: session.data?.user?.email
    })

    const prompt = inputValue;

    if (prompt) {
      setSessionStorage(prev => (
        {
          ...prev,
          isGeneratingImage: true,
          numImagesGenerated: (sessionStorage.numImagesGenerated || 0) + 1
        }
      ))
      const response = await generateImages(prompt, generatedImages.map(item => item.src), props.productSlug, props.colorType);

      if (response.isKnownCharacter) {
        toast({
          title: "Warning!",
          description: "Our model doesn't work with known people, anime characters, TV Shows, etc. This is just a warning. Your design is still being generated.",
          variant: 'destructive',
          duration: 10000,
        })
      }

      if (response.requestId) {
        const interval = setInterval(() => {
          updateImages(response.requestId, interval);
        }, 1500);
      }
      else {
        if (response?.login) {
          toast({
            title: "Login Required",
            description: "Please login to continue. It is completely free!",
            variant: 'destructive',
            duration: 10000,
            action: <ToastAction altText="Login" onClick={onToastActionClick} onTouchStart={onToastActionClick}>Login</ToastAction>
          })

        }
        else
          toast({
            description: response.error || "Something went wrong. Please try again later.",
            variant: 'destructive',
            duration: 3000
          })

        setSessionStorage(prev => (
          { ...prev, isGeneratingImage: false }
        ))
      }
    }
  };

  const handleConfirmReset = () => {
    setShowResetModal(false);
    handleReset();
  };

  const handleReset = () => {
    setGeneratedImages([]);
    localStorage.setItem('promptImages', '[]')
    if (props.onReset)
      props.onReset()
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handlePromptImageClick = (src: string) => {
    props.handlePromptImageClick(src)
  }

  return (
    <div className="my-3 w-full px-0 md:px-2">
      <FeedbackModal />
      <div className="flex flex-row items-center justify-center">
        <div className="grid w-full grid-flow-col items-center justify-stretch p-0">
          <PlaceholdersAndVanishInput
            placeholders={placeholders}
            showBorder={generatedImages.length === 0}
            onSubmit={handlePromptSubmit}
            onChange={handleChange}
            inputDisabled={sessionStorage.isGeneratingImage}
          />
        </div>

        <Dialog open={showResetModal} onOpenChange={setShowResetModal}>
          <DialogContent className="z-[100]">
            <DialogHeader >
              <DialogTitle>Clear Results?</DialogTitle>
            </DialogHeader>
            <div className="flex min-h-20 items-center">
              <p>Are you sure you want to clear all the results?</p>
            </div>
            <DialogFooter className="flex flex-row gap-2 justify-end pb-2">
              <Button variant="outline" onClick={() => setShowResetModal(false)} id="reset-cancelled">
                Cancel
              </Button>
              <Button variant="default" onClick={handleConfirmReset} id="reset-confirmed">
                Yes, Clear
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      <AnimatePresence>
        {sessionStorage.isGeneratingImage && (
          <motion.div
            initial={{ opacity: 0, filter: "blur(8px)", height: "0px" }}
            animate={{ opacity: 1, filter: "blur(0px)", height: 'auto' }}
            transition={{
              delay: 0.1,
              duration: 0.3,
            }}
            exit={{ opacity: 0, filter: "blur(8px)", height: "0px" }}
          >
            <div className="mt-3">Generating: {inputValue}</div>
            <progress className="progress progress-primary w-full transition-all" />
            <LetsTalk />
          </motion.div>
        )}
      </AnimatePresence>
      {generatedImages.length == 0 && <ImageList rainbow images={sampleImages.map(x => x.image)} selectedImages={props.selectedImages} onImageClick={handlePromptImageClick} />}
      {generatedImages.length > 0 && (
        <div className="my-2">
          <div className="flex mt-3  items-center justify-between">

            <div>
              <h2 className="text-sm md:text-xl font-semibold dark:text-white">
                Generated Images
              </h2>
              <p className="text-xs sm:text-sm text-gray-500">
                Select images to add to your design
              </p>
            </div>


            <TooltipProvider>
            <Tooltip>
              <TooltipTrigger >
                <div className="flex items-center justify-center rounded-full border-red-600 sm:p-1 text-red-600 transition-all hover:bg-red-600 hover:text-white xs:border">
                  <button
                    className="flex items-center justify-center text-sm"
                    onClick={() => setShowResetModal(true)}
                    disabled={sessionStorage.isGeneratingImage}
                    id="reset-button"
                  >
                    <XCircleIcon className="size-6" />
                    <span className="hidden pe-2 ps-1 text-sm xs:block">Reset</span>
                  </button>
                </div>
              </TooltipTrigger>
              <TooltipContent side="top" align="center" className="z-[99] bg-gray-800 text-white px-2 py-1 rounded">
                Clear Results
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          </div>

          <ImageList images={generatedImages.map(x => x.src)} selectedImages={props.selectedImages} onImageClick={handlePromptImageClick} />
        </div>
      )}

    </div>
  );
};

