import { useState } from "react";
import { motion } from 'framer-motion'
import { cn } from "@/utils/cn";
import { CiCircleCheck } from 'react-icons/ci';
import ImageWithLoader from "../others/ImageWithLoader";
import { useGeneratedImages } from "@/contexts/GeneratedImagesProvider";

export const PromptImage = (props: {
    src: string;
    selected: boolean;
    onClick: (src: string) => void;
    rainbow?: boolean
}) => {
    const [generatedImages, setGeneratedImages] = useGeneratedImages()
    const imgIndex = generatedImages.findIndex((item) => item.src === props.src);

    const handleClick = (e: React.MouseEvent<HTMLImageElement>) => {
        props.onClick(props.src);
        setGeneratedImages((prev) => {
            return prev.map((item, index) => {
                if (index === imgIndex) {
                    return { ...item, isClicked: true }
                }
                return item
            }
            )
        })
    };


    return (
        <motion.div className={cn('w-full aspect-square bg-gradient-to-tr from-slate-400 via-slate-100 to-slate-400 p-[1px] rounded-md hover:scale-[1.01] group transition-all ', {
            'rainbow-input p-0.5': props.rainbow
        })}
            initial={{ opacity: 0, filter: "blur(8px)" }}
            animate={{ opacity: 1, filter: "blur(0px)" }}
            transition={{
                delay: 0.1,
                duration: 0.2,
            }}
        >
            <div className={`h-full relative rounded-md bg-white transition-all group-hover:bg-slate-100 overflow-hidden border shadow-md`}>
                {props.selected && (
                    <div className="pointer-events-none absolute flex size-full items-center justify-center bg-black bg-opacity-30 text-green-200 z-20 ">
                        <CiCircleCheck className="size-10" />
                    </div>
                )}
                {!generatedImages[imgIndex]?.isClicked && (
                    <div className="absolute right-2 top-2 size-2 rounded-full   bg-gradient-to-b from-amber-600 to-yellow-400 z-20"></div>
                )}

                <div className='size-full relative'>
                    <ImageWithLoader
                        src={props.src}
                        spinnerSize='md'
                        alt={`Generated image`}
                        sizes='(max-width: 530px) 24vw, (max-width: 768px) 19vw, (max-width: 1024px) 10vw, 8vw'
                        fill
                        className={`z-10 cursor-pointer object-cover transition generated-image`}
                        data-track="Generated Image"
                        onClick={handleClick}
                        priority={true}
                        loading='eager'
                        key={props.src}
                    />
                </div>

            </div>
        </motion.div>

    );
};

