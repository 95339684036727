'use client'
import React, { createContext, SetStateAction, useEffect, useState } from 'react'

type CommonContextType = {
    numImagesGenerated: number
    isFeedbackSubmitted: boolean
    isGeneratingImage: boolean
}

const SessionStorageKey = 'sessionStorageContext'

const SessionStorageContext = createContext<[CommonContextType, React.Dispatch<SetStateAction<CommonContextType>>] | null>(null)

export default function SessionStorageContextProvider({ children }: { children: React.ReactNode }) {
    const [value, setValue] = useState<CommonContextType>({
        numImagesGenerated: 0,
        isFeedbackSubmitted: false,
        isGeneratingImage: false
    })

    useEffect(() => {
        const storedValue = sessionStorage.getItem(SessionStorageKey)
        if (storedValue) {
            setValue(JSON.parse(storedValue))
        }
    }, [])

    useEffect(() => {
        sessionStorage.setItem(SessionStorageKey, JSON.stringify(value))
    }, [value])

    return (
        <SessionStorageContext.Provider value={[value, setValue]}>
            {children}
        </SessionStorageContext.Provider>
    )
}


export const useSessionStorageContext = () => {
    const context = React.useContext(SessionStorageContext)
    if (context === null) {
        throw new Error('useCommonContext must be used within a CommonContextProvider')
    }
    return context
}