'use client'
import { ColorType } from '@/utils/APIRouteTypes'
import React, { createContext, SetStateAction, useEffect, useState } from 'react'

type GeneratedImagesContextType = {
    src: string,
    isClicked: boolean,
    color: ColorType
}[]

const GeneratedImagesContext = createContext<[GeneratedImagesContextType, React.Dispatch<SetStateAction<GeneratedImagesContextType>>] | null>(null)

export default function GeneratedImagesContextProvider({ children }: { children: React.ReactNode }) {

    const [value, setValue] = useState<GeneratedImagesContextType>([])

    useEffect(() => {
        try {
            const x = localStorage.getItem('promptImages')
            let json = JSON.parse(x || '[]')
            //check type of json
            if (!Array.isArray(json) || json.some((x: any) => typeof x !== 'object' || typeof x.src !== 'string' || typeof x.isClicked !== 'boolean' || typeof x.color !== 'string')) {
                json = []
            }
            setValue(json)
        }
        catch (e) {
            console.error(e)
        }
    }, [])

    useEffect(() => {
        if (value.length > 0)
            localStorage.setItem('promptImages', JSON.stringify(value))
    }, [JSON.stringify(value)])

    return (
        <GeneratedImagesContext.Provider value={[value, setValue]}>
            {children}
        </GeneratedImagesContext.Provider>
    )
}

export const useGeneratedImages = () => {
    const context = React.useContext(GeneratedImagesContext)
    if (context === null) {
        throw new Error('useCommonContext must be used within a CommonContextProvider')
    }
    return context
}